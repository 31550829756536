<template>
  <div class="content-wrapper">
    <h1 class="mb-5">Product Management</h1>
    <b-card class="mb-4">
      <b-row>
        <b-col class="text-lg-right">
          <b-button size="sm" class="mb-2" v-b-toggle.sidebar-product>
            <b-icon icon="plus" aria-hidden="true"></b-icon> New product
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-table
        hover
        v-sortable-table
        @newListOrder="putNewListOrder"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        >1095f4
        <template #cell(foto_prodotto)="row">
          <div class="grid-center">
            <img
              v-if="row.item.foto_prodotto"
              :src="row.item.foto_prodotto"
              style="width: 100%; border-radius: 5px"
            />
            <div v-else>
              <b-icon-image />
            </div>
          </div>
        </template>
        <template #cell(actions)="row">
          <b-dropdown>
            <template #button-content>
              <b-icon-gear-fill></b-icon-gear-fill>
            </template>

            <b-dropdown-item
              href="#"
              v-b-toggle.sidebar-product
              @click="
                currentItem = row.item;
                tmpItem = JSON.parse(JSON.stringify(row.item));
              "
              >Edit</b-dropdown-item
            >
            <b-dropdown-item @click="deleteItem(row.item.id)"
              >Delete</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="mt-4 mb-1"
      ></b-pagination>
    </b-card>
    <b-sidebar
      id="sidebar-product"
      class="right-sidebar"
      right
      shadow
      lazy
      @hidden="resetSidebar"
    >
      <div class="content-wrapper my-3 mx-1">
        <h4 v-if="currentItem">Edit Product</h4>
        <h4 v-else>New Product</h4>
        <div>
          <b-row>
            <b-col>
              <b-form-group label="Nome">
                <b-form-input v-model="tmpItem.nome" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label-for="role_list">
                <template #label>
                  Immagine prodotto
                  <InfoPopover class="ml-1" text="Carica un file ecc." />
                </template>

                <b-form-file
                  class="drop-file"
                  v-model="file"
                  drop-placeholder="Drop file here..."
                  accept="image/jpeg, image/png, image/gif"
                >
                  <template #placeholder>
                    <img
                      v-if="tmpItem.foto_prodotto.trim() != ''"
                      :src="tmpItem.foto_prodotto"
                      class="img-fluid uploader_image_preview"
                    />
                    <div v-else class="my-4 text-center">
                      <p class="h1 mb-1">
                        <b-icon-cloud-arrow-up-fill />
                      </p>
                      <p>
                        Choose a file or drop it here <br />
                        (.jpg, .png, .pdf, .gif)
                      </p>
                    </div>
                  </template>
                  <template slot="file-name">
                    <img
                      :src="file_url"
                      class="img-fluid uploader_image_preview"
                    />
                  </template>
                </b-form-file>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-checkbox class="mb-4" v-model="tmpItem.attivo"
                >Attivo</b-form-checkbox
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Descrizione">
                <b-form-textarea
                  id="textarea"
                  v-model="tmpItem.descrizione"
                  rows="5"
                  max-rows="10"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col>
              <b-row>
                <b-col>
                  <b-form-group label="Categoria">
                    <b-form-input disabled v-model="tmpItem.categoria" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Stock">
                    <b-form-input v-model.number="tmpItem.stock"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Prezzo lordo">
                <b-form-input
                  type="number"
                  v-model.number="tmpItem.prezzo_lordo"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Prezzo netto">
                <b-form-input v-model.number="tmpItem.prezzo_netto" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Margine">
                <b-form-input v-model.number="tmpItem.margine" />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Tipo di margine">
                <b-form-select
                  v-model="tmpItem.margine_type"
                  :options="margine_typeList"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="mt-5 mb-5">
          <b-button
            v-if="isLoading"
            squared
            variant="primary"
            size="lg"
            class="big-button"
            disabled
            ><b-spinner small type="grow"></b-spinner> Loading...</b-button
          >
          <b-button
            v-else
            squared
            variant="primary"
            size="lg"
            class="big-button"
            @click="saveItem"
            >Save</b-button
          >
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import AxiosService from "../../axiosServices/AxiosService";
// SalesChat/GetProducts
export default {
  getService: null,
  postService: null,
  putService: null,
  deleteService: null,
  name: "ProductManagement",
  data() {
    return {
      file: null,
      isLoading: false,
      currentItem: null,
      tmpItem: {},
      currentPage: 1,
      perPage: 10,
      filter: "",
      fields: [
        { key: "id", label: "Codice Prodotto", thStyle: { width: "5%" } },
        { key: "foto_prodotto", label: "Immagine", thStyle: { width: "10%" } },
        { key: "nome", label: "Nome Prodotto", sortable: true },
        { key: "descrizione", label: "Descrizione", sortable: true },
        { key: "prezzo_lordo", label: "Prezzo lordo", sortable: true },
        { key: "prezzo_netto", label: "Prezzo netto", sortable: true },
        { key: "margine", label: "Margine", sortable: true },

        { key: "actions", label: "Actions" },
      ],
      items: [],
      margine_typeList: [
        { value: "", text: "Seleziona il tipo" },
        { value: "euro", text: "Numero" },
        { value: "perc", text: "Percentuale" },
      ],
    };
  },
  created() {
    this.getService = new AxiosService("SalesChat/GetProducts");
    this.postService = new AxiosService("SalesChat/InsertProduct");
    this.putService = new AxiosService("SalesChat/UpdateProduct");
    this.deleteService = new AxiosService("SalesChat/DeleteProduct");

    this.resetSidebar();
  },
  mounted() {
    this.getService.read().then((res) => {
      this.items = res;
    });
  },
  methods: {
    putNewListOrder(oldIndex, newIndex) {
      // console.log(
      //   "🚀 ~ file: ProductManagement.vue ~ line 271 ~ putNewListOrder ~ oldIndex, newIndex",
      //   oldIndex,
      //   newIndex
      // );
      const getChangingList = function (arr, oldIndex, newIndex) {
        let subArr = [];
        let list = [];

        if (oldIndex < newIndex) {
          subArr = arr.slice(oldIndex, newIndex + 1);
        } else {
          subArr = arr.slice(newIndex, oldIndex + 1);
        }

        for (let i = 0; i < subArr.length; i++) {
          list.push({ ID: subArr[i].id, Ordine: i });
        }
        return list;
      };

      // let changingList = getChangingList(this.items, oldIndex, newIndex);
      getChangingList(this.items, oldIndex, newIndex);

      // console.log(this.items.map((x) => x.id));
      // console.log(
      //   changingList
      //     .map((a) => "ID: " + a.ID + " - Ordine: " + a.Ordine)
      //     .join("\n")
      // );
    },
    async saveItem() {
      this.isLoading = true;
      let item = { ...this.tmpItem };

      const formData = new FormData();

      if (this.file) {
        formData.append("pic", this.file);
        await this.putService
          .uploadFile(formData)
          .then((res) => {
            console.log("UPLOAD FILE RES ", res);
            let baseUrl = this.$driveAddress;
            let imageUrl = baseUrl + res.data;
            console.log("this.$driveAddress ", this.$driveAddress);
            console.log("baseUrl ", baseUrl);
            console.log("imageUrl ", imageUrl);
            item.foto_prodotto = imageUrl.replaceAll(" ", "%20");
            console.log("ITEM FOTO_PRODOTTO ", item.foto_prodotto);
          })
          .catch(() => {
            this.$errorToast();
            return false;
          });
      }

      if (item.id) {
        console.log("ITEM.ID TRUE , ", item);
        this.putService
          .update(item)
          .then((res) => {
            if (res.id == item.id) {
              Object.keys(this.currentItem).forEach((element) => {
                if (Object.prototype.hasOwnProperty.call(item, element)) {
                  this.currentItem[element] = item[element];
                }
              });
              this.$root.$emit("bv::toggle::collapse", "sidebar-partners");
              this.$successToast();
            }
          })
          .catch(() => {
            this.$errorToast();
            return false;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        console.log("ITEM.ID false , ", item);
        this.postService
          .create(item)
          .then((res) => {
            try {
              if (res.id) {
                item.id = res.id;
                this.items.push(item);
                this.$root.$emit("bv::toggle::collapse", "sidebar-product");
                this.$successToast();
              }
            } catch (err) {
              this.$errorToast();
              return false;
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    deleteItem(id) {
      this.deleteService
        .delete(id)
        .then((res) => {
          if (res) {
            this.items = this.items.filter(
              (currentItem) => id != currentItem.id
            );
            this.$successToast();
          } else {
            this.$errorToast();
            return false;
          }
        })
        .catch(() => {
          this.$errorToast();
          return false;
        });
    },

    resetSidebar() {
      this.currentItem = null;
      this.tmpItem = {
        id: 0,
        id_categoria: 0,
        nome: "",
        descrizione: "",
        foto_prodotto: "",
        prezzo_netto: 0,
        prezzo_lordo: 0,
        margine: 0,
        stock: 0,
        attivo: true,
        margine_type: "",
      };
    },
  },
  computed: {
    totalRows() {
      return this.items ? this.items.length : 0;
    },
    file_url() {
      if (this.file) {
        return URL.createObjectURL(this.file);
      } else {
        return "";
      }
    },
  },
  //   watch: {
  //     filter: {
  //       handler() {
  //         this.currentPage = 1;
  //       },
  //     },
  //   },
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>